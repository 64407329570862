.icons {
  display: inline-block;
  background-repeat: no-repeat;
}

.icon-size {
  width: 172px;
  height: 100px;
}

.icon-icon-usp-01 {
  background-image: url('/svg/icons-usp-01.svg');
}

.icon-icon-usp-02 {
  width: 172px;
  height: 100px;
  background-image: url('/svg/icons-usp-2.svg');
}

.icon-icon-usp-03 {
  width: 172px;
  height: 100px;
  background-image: url('/svg/icons-usp-03.svg');
}
.icon-icon-usp-04 {
  width: 172px;
  height: 100px;
  background-image: url('/svg/icons-usp-04.svg');
}
.icon-icon-usp-05 {
  width: 172px;
  height: 100px;
  background-image: url('/svg/icons-usp-05.svg');
}
.icon-icon-usp-06 {
  width: 172px;
  height: 100px;
  background-image: url('/svg/icons-usp-06.svg');
}
.icon-icons-01 {
  width: 83px;
  height: 100px;
  background-image: url('/svg/icons-01.svg');
}
.icon-icons-02 {
  width: 83px;
  height: 100px;
  background-image: url('/svg/icons-02.svg');
}
.icon-icons-03 {
  width: 159px;
  height: 100px;
  background-image: url('/svg/icons-03.svg');
}
.icon-icons-04 {
  width: 80px;
  height: 100px;
  background-image: url('/svg/icons-04.svg');
}
.icon-icons-05 {
  width: 80px;
  height: 100px;
  background-image: url('/svg/icons-05.svg');
}
.icon-icons-06 {
  width: 80px;
  height: 100px;
  background-image: url('/svg/icons-06.svg');
}
.icon-icons-07 {
  width: 80px;
  height: 100px;
  background-image: url('/svg/icons-07.svg');
}
.icon-icons-08 {
  width: 90px;
  height: 100px;
  background-image: url('/svg/icons-08.svg');
}
.icon-icons-09 {
  width: 79px;
  height: 100px;
  background-image: url('/svg/icons-09.svg');
}
.icon-icons-10 {
  width: 89px;
  height: 100px;
  background-image: url('/svg/icons-10.svg');
}
.icon-icons-11 {
  width: 89px;
  height: 100px;
  background-image: url('/svg/icons-11.svg');
}
.icon-icons-12 {
  width: 66px;
  height: 100px;
  background-image: url('/svg/icons-12.svg');
}
.icon-icons-13 {
  width: 98px;
  height: 100px;
  background-image: url('/svg/icons-13.svg');
}
.icon-icons-14 {
  width: 118px;
  height: 100px;
  background-image: url('/svg/icons-14.svg');
}
.icon-icons-15 {
  width: 118px;
  height: 100px;
  background-image: url('/svg/icons-15.svg');
}
.icon-icons-16 {
  width: 118px;
  height: 100px;
  background-image: url('/svg/icons-16.svg');
}
.icon-icons-17 {
  width: 118px;
  height: 100px;
  background-image: url('/svg/icons-17.svg');
}
.icon-icons-18 {
  width: 118px;
  height: 100px;
  background-image: url('/svg/icons-18.svg');
}
.icon-icons-19 {
  width: 118px;
  height: 100px;
  background-image: url('/svg/icons-19.svg');
}
.icon-icons-20 {
  width: 118px;
  height: 100px;
  background-image: url('/svg/icons-20.svg');
}
.icon-icons-21 {
  width: 118px;
  height: 100px;
  background-image: url('/svg/icons-21.svg');
}
.icon-icons-22 {
  width: 192px;
  height: 100px;
  background-image: url('/svg/icons-22.svg');
}

.icon-icons-25 {
  width: 155px;
  height: 100px;
  background-image: url('/svg/icons-25.svg');
}
.icon-icons-26 {
  width: 155px;
  height: 100px;
  background-image: url('/svg/icons-26.svg');
}
.icon-icons-27 {
  width: 74px;
  height: 100px;
  background-image: url('/svg/icons-27.svg');
}
.icon-icons-28 {
  width: 155px;
  height: 100px;
  background-image: url('/svg/icons-28.svg');
}
.icon-icons-29 {
  width: 94px;
  height: 100px;
  background-image: url('/svg/icons-29.svg');
}
.icon-icons-30 {
  width: 240px;
  height: 94px;
  background-image: url('/svg/icons-30.svg');
}
.icon-icons-31 {
  width: 240px;
  height: 94px;
  background-image: url('/svg/icons-31.svg');
  background-position: center;
}
.icon-icons-32 {
  width: 240px;
  height: 94px;
  background-image: url('/svg/icons-32.svg');
  background-position: center;
}

.icon-icons-renting {
  width: 100px;
  height: 100px;
  background-image: url('/svg/icons-12.svg');
}
