/********* Fonts *********
 * Oswald: Regular, Light, Bold
 * Source Sans Pro: ExtraLight, Light, Semibold, Bold, Regular
 */

@import './icons.css';

:root {
  /* #region  /**=========== Primary Color =========== */
  /* !STARTERCONF Customize these variable, copy and paste from /styles/colors.css for list of colors */
  --tw-color-primary-50: 240 249 255;
  --tw-color-primary-100: 224 242 254;
  --tw-color-primary-200: 186 230 253;
  --tw-color-primary-300: 125 211 252;
  --tw-color-primary-400: 56 189 248;
  --tw-color-primary-500: 14 165 233;
  --tw-color-primary-600: 2 132 199;
  --tw-color-primary-700: 3 105 161;
  --tw-color-primary-800: 7 89 133;
  --tw-color-primary-900: 12 74 110;
  --color-primary-50: rgb(var(--tw-color-primary-50)); /* #f0f9ff */
  --color-primary-100: rgb(var(--tw-color-primary-100)); /* #e0f2fe */
  --color-primary-200: rgb(var(--tw-color-primary-200)); /* #bae6fd */
  --color-primary-300: rgb(var(--tw-color-primary-300)); /* #7dd3fc */
  --color-primary-400: rgb(var(--tw-color-primary-400)); /* #38bdf8 */
  --color-primary-500: rgb(var(--tw-color-primary-500)); /* #0ea5e9 */
  --color-primary-600: rgb(var(--tw-color-primary-600)); /* #0284c7 */
  --color-primary-700: rgb(var(--tw-color-primary-700)); /* #0369a1 */
  --color-primary-800: rgb(var(--tw-color-primary-800)); /* #075985 */
  --color-primary-900: rgb(var(--tw-color-primary-900)); /* #0c4a6e */
  /* #endregion  /**======== Primary Color =========== */

  --color-mutted-500: #4d4d4d '';
}

@layer base {
  /* inter var - latin */
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100 900;
    font-display: optional;
    src: url('/fonts/inter-var-latin.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }

  /* #region  /**=========== Typography =========== */
  .h0 {
    @apply font-primary text-3xl font-bold md:text-5xl;
  }

  h1,
  .h1 {
    @apply font-secondary text-4xl font-extralight text-brand-500;
    line-height: 1.1;
  }

  h2,
  .h2 {
    @apply font-secondary text-3xl font-extralight text-brand-500;
    line-height: 1.1;
  }

  h3,
  .h3 {
    @apply font-secondary text-2xl font-extralight text-brand-500;
    line-height: 1.1;
  }

  h4,
  .h4 {
    @apply font-secondary text-lg font-extralight text-brand-500;
    line-height: 1.1;
  }

  .text-nav {
    @apply cursor-pointer font-secondary text-[0.9rem] uppercase tracking-wide text-zinc-600;
  }

  body,
  .p {
    @apply font-primary text-base;
  }

  button {
    @apply font-secondary;
  }

  /* #endregion  /**======== Typography =========== */

  .layout {
    /* 1100px */
    max-width: 68.75rem;
    @apply mx-auto w-11/12;
  }

  .bg-dark a.custom-link {
    @apply border-gray-200 hover:border-gray-200/0;
  }

  /* Class to adjust with sticky footer */
  .min-h-main {
    @apply min-h-[calc(100vh-56px)];
  }

  .link {
    @apply text-brand-500 underline;
  }
}

@layer utilities {
  .animated-underline {
    background-image: linear-gradient(#33333300, #33333300),
      linear-gradient(
        to right,
        var(--color-primary-400),
        var(--color-primary-500)
      );
    background-size: 100% 2px, 0 2px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
  }
  @media (prefers-reduced-motion: no-preference) {
    .animated-underline {
      transition: 0.3s ease;
      transition-property: background-size, color, background-color,
        border-color;
    }
  }
  .animated-underline:hover,
  .animated-underline:focus-visible {
    background-size: 0 2px, 100% 2px;
  }
}

.inner-shaddow-hero:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    to bottom,
    #000 0,
    rgba(255, 255, 255, 0) 24.8%,
    rgba(255, 255, 255, 0) 60.5%,
    #000 100%
  );
  opacity: 0.7;
  z-index: 0;
  pointer-events: none;
}

.inner-shaddow-bottom:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    to bottom,
    #000 0,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 0%,
    #000 100%
  );
  opacity: 1;
  z-index: 0;
  pointer-events: none;
}

.form-input {
  @apply border-[1px] ring-0 focus:border-x-0 focus:border-b-2 focus:border-t-0 focus:outline-none focus:ring-0;
}

/* input number without arrows */
@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.quoted:before {
  content: open-quote;
  font-size: 5rem;
  color: var(--color-mutted-500);
  opacity: 0.5;
  position: relative;
  top: 5rem;
  left: 2.5rem;
}
.quoted:after {
  content: close-quote;
  font-size: 5rem;
  color: var(--color-mutted-500);
  opacity: 0.5;
  float: right;
  position: relative;
  bottom: 2rem;
  right: 2.5rem;
}

.btn {
  @apply h-10 w-10 border font-secondary uppercase tracking-wide text-brand-500 no-underline;
}

.print-image,
.print-image * {
  display: none !important;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .print-image,
  .print-image * {
    display: block !important;
    z-index: 9999;
    opacity: 1;
  }
}

/*

  MyGindumac

*/

@font-face {
  font-family: 'MoonTime';
  src: url('/mygindumac/fonts/MoonTime.ttf');
  font-weight: normal;
  font-style: normal;
}

.font-moontime {
  font-family: 'MoonTime';
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.MuiInputBase-root {
  height: 100%;
}

.MuiInputBase-input {
  height: 100%;
}
.MuiInputBase-input:focus {
  box-shadow: none !important;
}

.leaflet-pane {
  z-index: 1 !important;
}
